<template>
  <div class="main-panel mt-5">
    <product-form @on-submit="save" />
  </div>
</template>

<script>
import {productService} from "../../services/productService";
import productForm from "./Form.vue"

export default {
  components : {
    productForm
  },
  data() {
    return {
      id:null
    }
  },
  methods: {
    save(data) {
      productService.existsProduct(data.name).then(result => {
        if(result.data.exists) {
          this.$bvModal.msgBoxConfirm('Ви впевнені, що хочете перезаписати існуючий виріб?', {
            title: 'Підтвердіть',
            size: 'mm',
            buttonSize: 'sm',
            okVariant: 'danger',
            okTitle: 'Так',
            cancelTitle: 'Ні',
            footerClass: 'p-2',
            hideHeaderClose: false,
            centered: true
          })
              .then((value) => {
                if(value) {
                  this.id = result.data.id;
                  this.createProduct(data);
                }
              })
        } else {
          this.createProduct(data);
        }
      })
    },
    createProduct(data) {
      if(this.id !== null) {
        productService.updateProduct(this.id, data).then(response => {
          this.$toast.success("Успішно оновлено");
        }).then(r => {
          this.loading = false;
        }).catch(e => {
          this.loading = false;
          this.$toast.error("Щось пішло не так =(");
        })
      } else {
        productService.createProduct(data).then(response => {
          this.id = response.product.id;
          this.$toast.success("Успішно збережено");
        }).then(r => {
          this.loading = false;
        }).catch(e => {
          this.loading = false;
          let data = e.data;
          if(data.errors?.name) {
            this.$toast.error("Вкажіть назву виробу");
          } else {
            this.$toast.error("Щось пішло не так =(");
          }

        })
      }

    }
  },
  name: "product-create"
}
</script>

<style scoped>
</style>