<template>
  <div class="container h-100">
    <div class="row h-100 align-items-center">
      <div class="col-12 col-md-6 offset-md-3">
        <div class="card shadow sm">
          <div class="card-body">
            <h1 class="text-center">Авторизація</h1>
            <hr/>
            <form action="javascript:void(0)" class="row" method="post">
              <div class="form-group col-12">
                <label for="email" class="font-weight-bold">Пошта/Телефон</label>
                <input type="text" v-model="auth.email" name="email" id="email" class="form-control">
              </div>
              <div class="form-group col-12">
                <label for="password" class="font-weight-bold">Пароль</label>
                <input type="password" v-model="auth.password" name="password" id="password" class="form-control">
              </div>
              <div class="col-12 mb-2">
                <button type="submit" :disabled="processing" @click="login" class="btn btn-primary btn-block">
                  {{ processing ? "Зачекайте" : "Увійти" }}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {mapActions} from 'vuex'

export default {
  name: "login",
  data() {
    return {
      auth: {
        email: "",
        password: ""
      },
      processing: false
    }
  },
  methods: {
    ...mapActions({
      signIn: 'auth/login'
    }),
    async login() {
      this.processing = true
      await axios.post('/login', this.auth).then(({data}) => {
        data.user['defaultStock'] = data.defaultStock
        this.signIn(data)
      }).catch(({response: {data}}) => {
        alert(data.message)
      }).finally(() => {
        this.processing = false
      })
    },
  }
}
</script>