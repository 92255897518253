<template>
  <div class="container mt-5">
    <div class="row justify-content-center">
      <div class="col-md-6 ">
        <form class="needs-validation" novalidate>
          <div class="form-group">
            <label for="oldPassword">Старий пароль</label>
            <div class="input-group">
              <input :type="types.old_password"
                     v-model="form.old_password"
                     :class="errors?.old_password ? 'form-control is-invalid' : 'form-control'"
                     id="oldPassword">
              <div class="input-group-append">
                <button type="button" @click="switchType('old_password')" class="input-group-text">
                  <template v-if="types.old_password === 'password'">
                    <i class="fa fa-eye" aria-hidden="true"></i>
                  </template>
                  <template v-else>
                    <i class="fa fa-eye" aria-hidden="true"></i>
                  </template>
                </button>
              </div>
              <div class="invalid-feedback" v-if="errors?.old_password">{{ errors.old_password[0] }}</div>

            </div>

          </div>
          <div class="form-group">
            <label for="password">Новий пароль</label>
            <div class="input-group">
              <input :type="types.new_password"
                     v-model="form.new_password"
                     :class="errors?.new_password ? 'form-control is-invalid' : 'form-control'"
                     id="password">
              <div class="input-group-append">
                <button type="button" @click="switchType('new_password')" class="input-group-text">
                  <template v-if="types.new_password === 'password'">
                    <i class="fa fa-eye" aria-hidden="true"></i>
                  </template>
                  <template v-else>
                    <i class="fa fa-eye" aria-hidden="true"></i>
                  </template>
                </button>
              </div>
              <div class="invalid-feedback" v-if="errors?.new_password">{{ errors.new_password[0] }}</div>

            </div>
          </div>
          <div class="form-group">
            <label for="new_password_confirmation">Підтвердження нового паролю</label>
            <div class="input-group">
              <input :type="types.new_password_confirmation"
                     v-model="form.new_password_confirmation"
                     :class="errors?.new_password_confirmation ? 'form-control is-invalid' : 'form-control'"
                     id="new_password_confirmation">
              <div class="input-group-append">
                <button type="button" @click="switchType('new_password_confirmation')" class="input-group-text">
                  <template v-if="types.new_password_confirmation === 'password'">
                    <i class="fa fa-eye" aria-hidden="true"></i>
                  </template>
                  <template v-else>
                    <i class="fa fa-eye" aria-hidden="true"></i>
                  </template>
                </button>
              </div>
              <div class="invalid-feedback" v-if="errors?.new_password_confirmation">{{ errors.new_password_confirmation[0] }}</div>

            </div>
          </div>
          <button :disabled="loading" type="button" @click="changePassword" class="btn btn-danger">
            <span v-show="loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            {{ loading ? "Змінюємо" : "Змінити" }}
          </button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      types : {
        'old_password': 'password',
        'new_password': 'password',
        'new_password_confirmation': 'password',
      },
      form: {
        'old_password': '',
        'new_password': '',
        'new_password_confirmation': '',
      },
      loading: false,
      errors: {},
    }
  },
  methods: {
    switchType(field) {
      this.types[field] = this.types[field] === "password" ? "text" : "password";
    },
    changePassword(v) {
      this.loading = true;
      this.errors = {};
      axios.post('/user/password/change', this.form)
          .then(response => response.data)
          .then((response) => {
            this.loading = false;
            if (response.success) {
              this.$toast.success(response.message)
            } else {
              this.$toast.error(response.message)
            }
          }).catch(error => {
        if (error.response.status === 422) {
          this.errors = error.response.data.errors
        } else {
          this.$toast.error('Щось пішло не так =(')
        }
        this.loading = false;
      })
    }
  },
  name: "settings"
}
</script>

<style scoped>

</style>