<template>
  <form ref="form">
    <b-row>

      <b-col>
        <b-row>
          <b-col>
            <b-card title-tag='p' title="Кут знизу ліворуч">
              <b-form-group
                  label="Відступ зліва"
                  label-for="p3"
                  type="number"
              >
                <b-form-input
                    id="p3"
                    size="sm"
                    aria-describedby="input-p3"
                    :state="detailValidation('P3_')"
                    v-model.number="detail.p3"
                ></b-form-input>
                <b-form-invalid-feedback id="input-p3">
                  {{ detailValidationText('P3_') }}
                </b-form-invalid-feedback>
              </b-form-group>
              <b-form-group
                  label="Відступ знизу"
                  label-for="p4"
                  type="number"
              >
                <b-form-input
                    size="sm"
                    type="number"
                    aria-describedby="input-p4"
                    :state="detailValidation('P4_')"
                    id="p4"
                    v-model.number="detail.p4"
                ></b-form-input>
                <b-form-invalid-feedback id="input-p4">
                  {{ detailValidationText('P4_') }}
                </b-form-invalid-feedback>
              </b-form-group>
            </b-card>
          </b-col>
        </b-row>
      </b-col>
      <b-col>
        <b-row>
          <b-col>
            <b-card title-tag='p' title="Кут знизу праворуч">
              <b-form-group
                  label="Відступ зправа"
                  label-for="p1"
              >
                <b-form-input
                    id="p1"
                    size="sm"
                    aria-describedby="input-p1"
                    :state="detailValidation('P1_')"
                    type="number"
                    v-model.number="detail.p1"
                ></b-form-input>
                <b-form-invalid-feedback id="input-p1">
                  {{ detailValidationText('P1_') }}
                </b-form-invalid-feedback>
              </b-form-group>
              <b-form-group
                  label="Відступ знизу"
                  label-for="p2"
              >
                <b-form-input
                    type="number"
                    id="p2"
                    size="sm"
                    aria-describedby="input-p2"
                    :state="detailValidation('P2_')"
                    v-model.number="detail.p2"
                ></b-form-input>
                <b-form-invalid-feedback id="input-p2">
                  {{ detailValidationText('P2_') }}
                </b-form-invalid-feedback>
              </b-form-group>
            </b-card>

          </b-col>
        </b-row>
      </b-col>
      <b-col cols="12">
        <b-form-group
            label="Примітка"
            label-for="note"
        >
          <b-form-input
              id="note"
              size="sm"
              v-model="detail.note"
          ></b-form-input>
        </b-form-group>
      </b-col>
      <b-col cols="12">
        <b-form-group
            label="Оклеювання кромкою"
            label-for="edge_template"
        >
          <b-form-select size="sm"
                         aria-describedby="input-edge_template"
                         :state="detailValidation('EdgeA1_')"
                         id="edge_template" v-model="detail.edge_template">
            <b-form-select-option :value="null">Ні</b-form-select-option>
            <b-form-select-option v-for="(edge, i) in edges" :value="edge.ref">
              {{ (i + 1) + ' ' }} {{ edge.full_name }}
            </b-form-select-option>
          </b-form-select>
          <b-form-invalid-feedback id="input-edge_template">
            {{ detailValidationText('EdgeA1_') }}
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
    </b-row>

  </form>
</template>

<script>

import validation from "@/mixins/BaseTemplate"

export default {
  mixins: [ validation ],
  props: [
    'edges',
    'detail'
  ],
  computed: {
    resp() {
      return this.$store.state.data.response;
    },
  },
  watch: {
    detail: function (newValue, oldValue) {
      this.$root.$emit('update-detail', this.detail);
    }
  },
  methods: {
  },
  name: "Template_2",

}
</script>

<style scoped>

</style>