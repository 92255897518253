import Vue from 'vue'
import App from './App.vue'
import router from './router'

import '@/assets/scss/app.scss'

import '@fortawesome/fontawesome-free/css/all.css'
import '@fortawesome/fontawesome-free/js/all.js'
import 'simplebar'; // or "import SimpleBar from 'simplebar';" if you want to use it manually.
import 'simplebar/dist/simplebar.css';
import axios from 'axios';
import store from './store'
//conf
import appconfig from '@/app/config';
//main app & base
import app from '@/app/app';
import lang from '@/app/langs/lang';
import Toast from "vue-toastification";
// Import the CSS or use your own!
import "vue-toastification/dist/index.css";
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'

// Import Bootstrap and BootstrapVue CSS files (order is important)
import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

Vue.config.productionTip = false;

window.appconfig = appconfig;
window.app = app;
window.lang = lang;
// Set up axios globally:
window.axios = axios;
axios.defaults.baseURL = appconfig.Point + appconfig.apiPoint;
axios.defaults.headers.common['Accept'] = 'application/json';
axios.defaults.headers.common['Content-Type'] = 'application/json';

if (store.state.auth.token) {
    axios.defaults.headers.common['Authorization'] = `Bearer ${store.state.auth.token}`;
}
axios.interceptors.response.use(
    (response) => response,
    (error) => {
        if (error.response?.status === 401) {
            store.dispatch('auth/logout');
            axios.defaults.headers.common['Authorization'] = 'Bearer';
            window.location.href = '/login';
        }
        return Promise.reject(error);
    }
);

Vue.use(Toast);
// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)
window.vue = new Vue({
    router: router,
    store: store,
    render: h => h(App)
}).$mount('#app')

