<template>
  <form ref="form">
    <b-row>
      <b-col cols="12">
        <b-card title="Обробляємі сторони">
          <b-row>
            <b-col cols="6">
              <b-form-group
                  label="Кількість петель"
                  label-for="p1"
              >
                <b-form-input
                    id="p1" size="sm"
                    type="number"
                    aria-describedby="input-p1"
                    :state="detailValidation('P1_')"
                    v-model.number="detail.p1"
                ></b-form-input>
                <b-form-invalid-feedback id="input-p1">
                  {{ detailValidationText('P1_') }}
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group
                  label="Розташування"
                  label-for="p2"
              >
                <b-form-select size="sm"
                               aria-describedby="input-p2"
                               :state="detailValidation('P2_')"
                               id="p2" v-model.number="detail.p2">
                  <b-form-select-option value="0">За довжиною</b-form-select-option>
                  <b-form-select-option value="1">За шириною</b-form-select-option>
                </b-form-select>
                <b-form-invalid-feedback id="input-p2">
                  {{ detailValidationText('P2_') }}
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                  label="Примітка"
                  label-for="note"
              >
                <b-form-input
                    id="note"
                    size="sm"
                    v-model="detail.note"
                ></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
        </b-card>

      </b-col>

    </b-row>

  </form>
</template>

<script>
import validation from "@/mixins/BaseTemplate"

export default {
  mixins: [ validation ],
  props: [
    'detail'
  ],
  computed: {
    resp() {
      return this.$store.state.data.response;
    },
  },
  watch: {
    detail: function (newValue, oldValue) {
      this.$root.$emit('update-detail', this.detail);
    }
  },
  name: "Template_8"
}
</script>

<style scoped>

</style>