<template>
  <div class="container mt-5">
    <div class="row justify-content-center">
      <div class="col-md-6 ">
        <form class="needs-validation" novalidate>
          <div class="form-group">
            <label for="oldPassword">Вкажіть пошту</label>
            <div class="input-group">
              <input type="email"
                     v-model="form.email"
                     :class="errors?.email ? 'form-control is-invalid' : 'form-control'"
                     id="email">
              <div class="invalid-feedback" v-if="errors?.email">{{ errors.email[0] }}</div>

            </div>
            <div class="form-group">
              <label for="password">Новий пароль</label>
              <div class="input-group">
                <input :type="types.new_password"
                       v-model="form.new_password"
                       :class="errors?.new_password ? 'form-control is-invalid' : 'form-control'"
                       id="password">
                <div class="input-group-append">
                  <button type="button" @click="switchType('new_password')" class="input-group-text">
                    <template v-if="types.new_password === 'password'">
                      <i class="fa fa-eye" aria-hidden="true"></i>
                    </template>
                    <template v-else>
                      <i class="fa fa-eye" aria-hidden="true"></i>
                    </template>
                  </button>
                </div>
                <div class="invalid-feedback" v-if="errors?.new_password">{{ errors.new_password[0] }}</div>

              </div>
            </div>
            <div class="form-group">
              <label for="new_password_confirmation">Підтвердження нового паролю</label>
              <div class="input-group">
                <input :type="types.new_password_confirmation"
                       v-model="form.new_password_confirmation"
                       :class="errors?.new_password_confirmation ? 'form-control is-invalid' : 'form-control'"
                       id="new_password_confirmation">
                <div class="input-group-append">
                  <button type="button" @click="switchType('new_password_confirmation')" class="input-group-text">
                    <template v-if="types.new_password_confirmation === 'password'">
                      <i class="fa fa-eye" aria-hidden="true"></i>
                    </template>
                    <template v-else>
                      <i class="fa fa-eye" aria-hidden="true"></i>
                    </template>
                  </button>
                </div>
                <div class="invalid-feedback" v-if="errors?.new_password_confirmation">{{ errors.new_password_confirmation[0] }}</div>

              </div>
            </div>
          </div>
          <button :disabled="loading" type="button" @click="saveEmail" class="btn btn-danger">
            <span v-show="loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            {{ loading ? "Змінюємо" : "Змінити" }}
          </button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import router from "../../router";
import store from "../../store";

export default {
  data() {
    return {
      types : {
        'new_password': 'password',
        'new_password_confirmation': 'password',
      },
      form: {
        'old_password': '',
        'new_password': '',
        'new_password_confirmation': '',
      },
      loading: false,
      errors: {},
    }
  },
  methods: {
    switchType(field) {
      this.types[field] = this.types[field] === "password" ? "text" : "password";
    },
    saveEmail(v) {
      this.loading = true;
      this.errors = {};
      axios.post('/user/email/change', this.form)
          .then(response => response.data)
          .then((response) => {
            this.loading = false;
            if (response.success) {
              this.$store.commit('auth/SET_USER', response.user)
              location.reload();
            } else {
              this.$toast.error(response.message)
            }
          }).catch(error => {
        if (error.response.status === 422) {
          this.errors = error.response.data.errors
        } else {
          this.$toast.error('Щось пішло не так =(')
        }
        this.loading = false;
      })
    }
  },
  name: "update-profile"
}
</script>

<style scoped>

</style>