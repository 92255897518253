<template>
    <div>
        <div v-if="bidPriceItems.length">
            <button class="bidPriceModalBtn button" ref="bidPriceModalBtn" data-toggle="modal"
                    data-target="#bidPriceModal" title="Список на уточнення ціни">
                <i class="fa fa-list"></i>
            </button>
        </div>

    <div class="modal fade" id="bidPriceModal" tabindex="-1" role="dialog" aria-labelledby="bidPriceModalLabel"
         aria-hidden="true" style="overflow: auto!important;">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="bidPriceModalLabel">Список на уточнення ціни</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <ul class="bid__list" v-for="(item, index) in bidPriceItems" :data-key="index" :key="index">
                       <li>
                           {{index+1}}) {{item.full_name}}
                       </li>
                    </ul>

                    <div class="form-inline mt-4">
                        <label for="date" class="col-form-label col-5">Бажана дата</label>
                        <input v-model="date" id="date" type="date" class="form-control col-5"/>
                    </div>

                    <div class="form-inline mt-2">
                        <label for="comment" class="col-form-label col-5">Коментар</label>
                        <input v-model="comment" id="comment" type="text" class="form-control col-5"/>
                    </div>

                </div>

                <div class="modal-footer">
                    <button class="button" @click="bidPriceSend()" :disabled="logic">Відправити</button>
                    <button class="button button__grey" data-dismiss="modal" aria-label="Close">Видмінити</button>
                </div>
            </div>
        </div>
    </div>
    </div>
</template>

<script>
    import axios from "axios";

    export default {
        name: "bid-price-modal",
        props: ['bidPriceItems'],
        data(){
          return {
              date:'',
              comment:''
          }
        },
        computed:{
            logic(){
                return !this.date || this.bidPriceItems.length == 0;
            },
        },
        mounted() {
            if(this.bidPriceItems.length) {
                setTimeout(() => {
                    this.$refs.bidPriceModalBtn.classList.add('active')
                },1)
            }
        },
        methods: {
            bidPriceSend() {

                alert('Наразі логіка Уточнення цін у розробці!');

                // let bid_price = {
                //     ref: this.item.ref,
                //     dept: this.item.dept,
                //     full_name: this.item.full_name,
                //     sale_unit_name: this.item.sale_unit_name,
                //     step: this.item.step || this.item.ratio_sale || 1,
                //     number: this.item.step || this.item.ratio_sale || 1
                // };
                //
                // axios.post(appconfig.Point + '/api/constructor/v1/bid-price', {
                //     date: this.date,
                //     product: JSON.stringify(bid_price),
                //     comment: this.comment
                // }).then(response => {
                //     this.responseData = response.data;
                // }).catch(e => {
                //     console.log(e);
                // });
            }
        }
    }
</script>