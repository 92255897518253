<template>
  <div class="edge_table_wrapper">
    <table class="edge_table table table-bordered">
      <thead>
      <tr>
        <th>
          <div class="table__header">
            №
          </div>
        </th>
        <th>
          <div class="table__header" v-b-tooltip.hover.right="'По-замовчуванню'">
            <svg class="svg-inline--fa fa-question-circle fa-w-16 fa-xs" aria-hidden="true" focusable="false" data-prefix="fa" data-icon="question-circle" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg=""><path fill="currentColor" d="M504 256c0 136.997-111.043 248-248 248S8 392.997 8 256C8 119.083 119.043 8 256 8s248 111.083 248 248zM262.655 90c-54.497 0-89.255 22.957-116.549 63.758-3.536 5.286-2.353 12.415 2.715 16.258l34.699 26.31c5.205 3.947 12.621 3.008 16.665-2.122 17.864-22.658 30.113-35.797 57.303-35.797 20.429 0 45.698 13.148 45.698 32.958 0 14.976-12.363 22.667-32.534 33.976C247.128 238.528 216 254.941 216 296v4c0 6.627 5.373 12 12 12h56c6.627 0 12-5.373 12-12v-1.333c0-28.462 83.186-29.647 83.186-106.667 0-58.002-60.165-102-116.531-102zM256 338c-25.365 0-46 20.635-46 46 0 25.364 20.635 46 46 46s46-20.636 46-46c0-25.365-20.635-46-46-46z"></path></svg>
          </div>
        </th>
        <th colspan="2">
          <div class="table__header">
            Кромка
            <button class="button button__medium" data-toggle="modal"
                    data-target="#searchModal" @click="search('edges')">
              Добавить
            </button>
          </div>
        </th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="(edge, index) in edges" :key="index">
        <td>{{ index + 1 }}</td>
        <td>
          <div class="table__checkbox">
            <input :id="'e_'+index" class="checkbox-input" type="radio" v-model="edgeDefault"
                   :value="edge.ref" @change="change(index)">
            <label :for="'e_'+index"></label>
          </div>
        </td>
        <td class="text-color" :style="{color:edge.color}" :data-ref="edge.ref">{{ edge.full_name }}</td>
        <td>
          <div class="table__buttons">
            <b-button variant="outline-info" @click="replace(index)" data-toggle="modal" data-target="#searchModal">
              <i class="fas fa-exchange-alt"></i>
            </b-button>
            <b-button variant="outline-danger" @click="remove(index)">
              <i class="far fa-trash-alt"></i>
            </b-button>
          </div>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: "EdgeTable",
  props : [
    'toSearch'
  ],
  computed: {
    edgeDefault :{
      get() {
        let def = this.$store.getters['data/defaultEdge']
        return def ? def.ref : '';
      },
      set(value) {
        this.$store.commit('data/SET_DEFAULT_EDGES', value);
      }
    },
    edges() {
      return this.$store.state.data.edges;
    },
  },
  methods: {
    search(v) {
      app.toSearch = v;
      this.toSearch = v;
    },
    change(i) {
      this.edgeDefault = this.edges[i];
    },
    replace(i) {
      this.search('edges');
      app.replace = this.edges[i].ref;
    },
    remove(i) {
      if (!confirm('Данный материал кромки будет удален с деталировки! Удалить? ')) return;
      let ref = this.edges[i].ref;
      app.details.forEach((el) => {
        if (el.edge_t === ref) el.edge_t = null;
        if (el.edge_b === ref) el.edge_b = null;
        if (el.edge_l === ref) el.edge_l = null;
        if (el.edge_r === ref) el.edge_r = null;
        if (el.edge_template === ref) el.edge_template = null;
      })

      this.edges.splice(i, 1);
      this.$store.commit('data/SET_EDGES', this.edges);
      if (this.edgeDefault == i || this.edges.length == 0) {
        app.edgeDefault = 0
        this.$store.commit('data/SET_DEFAULT_EDGES', null);
      }
    }
  },
  mounted() {
    this.$root.$on('update', i => {
      this.$forceUpdate();
    });
  }
}
</script>