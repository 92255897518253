import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'

import Main from '../views/Main.vue'
import Products from '../views/Product/Index.vue'
import ProductsCreate from '../views/Product/Create.vue'
import ProductsUpdate from '../views/Product/Update.vue'
import Help from '../views/Help.vue'
import Settings from '../views/Settings.vue'
import OrdersList from '../views/Order/Index.vue'
import ObjectToFD from 'vue-object-to-formdata';
import login from '../components/auth/login.vue'

Vue.use(VueRouter);
Vue.use(ObjectToFD);


const routes = [
    {
        name: "login",
        path: "/login",
        component: login,
        meta: {
            middleware: "guest",
            title: `Login`
        }
    },
    {
        path: '/',
        name: 'Main',
        component: Main,
        meta: {
            middleware: "auth"
        },
    },
    {
        path: '/help',
        name: 'Help',
        component: Help,
        meta: {
            middleware: "auth"
        },
    },
    {
        path: '/delivery',
        name: 'Delivery',
        component: Help,
        meta: {
            middleware: "auth"
        },
    },
    {
        path: '/payment',
        name: 'Payment',
        component: Help,
        meta: {
            middleware: "auth"
        },
    },
    {
        path: '/prav',
        name: 'Prav',
        component: Help,
        meta: {
            middleware: "auth"
        },
    },
    {
        path: '/products',
        name: 'Products',
        component: Products,
        meta: {
            middleware: "auth",
            title : 'Список виробів'
        },
    },
    {
        path: '/products/create',
        name: 'Products-create',
        component: ProductsCreate,
        meta: {
            middleware: "auth",
            title : 'Створити виріб'
        },
    },
    {
        path: '/products/update/:id',
        name: 'Products-update',
        component: ProductsUpdate,
        meta: {
            middleware: "auth",
            title : 'Редагувати виріб'
        },
    },
    {
        path: '/settings',
        name: 'Settings',
        component: Settings,
        meta: {
            middleware: "auth"
        },
    },
    {
        path: '/orders',
        name: 'order',
        component: OrdersList,
        meta: {
            middleware: "auth"
        },
    },
    {
        path: '/orders/:id',
        name: 'order-edit',
        component: Main,
        meta: {
            middleware: "auth"
        },
    },

    {path: '*', redirect: '/'}
]


const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

const routing = (to, from, next) => {
    document.title = `${to.meta.title} - ${process.env.VUE_APP_MIX_APP_NAME}`;

    if(to.path == '/') {
        // store.commit('data/SET_DETAILS', []);
        // store.commit('data/SET_MATERIALS', []);
        // store.commit('data/SET_EDGES', []);
        // store.commit('data/SET_SCREEDS', []);
        // store.commit('data/SET_NAMES', []);
        // store.commit('data/SET_DETAIL', {});
    }
    if (to.meta.middleware === "guest") {
        if (store.state.auth.authenticated) {
            next({name: "Main"})
        }
        next()
    } else {
        if (store.state.auth.authenticated) {
            next()
        } else {
            next({name: "login"})
        }
    }
};

router.beforeEach(routing);

export default router
