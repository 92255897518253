import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import auth from './auth'
import data from './data'
Vue.use(Vuex)
export default new Vuex.Store({
    plugins:[
        createPersistedState()
    ],
    modules:{
        auth:auth,
        data:data
    }
})