<template>
  <div class="view__wrap home__page">
    <div v-if="isAuth && user.email == null">
      <email-form :email="user.email"></email-form>
    </div>
    <div v-else-if="isAuth">
      <div class="details">
        <div class="details__info">
          <div class="accordion" id="accordionExample">
            <div class="accordion_row" v-if="order">
              <div class="accordion_header" id="headingFour" data-toggle="collapse"
                   data-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                <h3>
                  Параметри замовлення
                </h3>
              </div>
              <div id="collapseFour" class="collapse accordion_content order-content" aria-labelledby="headingFour"
                   data-parent="#accordionExample">

                <div class="order-content__wrapper">
                  <div class="order-content__inputs">
                    <div class="item">
                      <input type="checkbox" class="checkbox-input" id="customer_material"
                             v-model="order.customer_material" value="true">
                      <label for="customer_material">Матеріал Клієнта</label>
                    </div>

                    <div class="item">
                      <input type="checkbox" class="checkbox-input" id="customer_edge"
                             v-model="order.customer_edge"
                             value="true">
                      <label for="customer_edge">Кромка Клієнта</label>
                    </div>
                    <div class="item">
                      <input type="checkbox" class="checkbox-input" id="edge_size"
                             v-model="order.edge_size" value="true">
                      <label for="edge_size">Розмір з Кромкою</label>
                    </div>

                    <div class="item">
                      <input type="checkbox" class="checkbox-input" id="glue" v-model="order.glue"
                             value="true">
                      <label for="glue">Оклеювання клеєм PUR</label>
                    </div>
                  </div>
                  <div class="order-content__comment">
                    <div>
                      <label for="date_order" class="col-form-label">Дата відвантаження</label>
                      <input disabled v-model="order.date_sale" id="date_order" type="date" class="form-control"/>
                    </div>
                    <div>
                      <label for="comment">Коментар</label>
                      <textarea id="comment" class="form-control" v-model="order.comment"
                                placeholder="Залишити коментар"></textarea>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="accordion_row">
              <div class="accordion_header" id="headingName" data-toggle="collapse"
                   data-target="#collapseName" aria-expanded="false" aria-controls="collapseName">
                <h3>Список виробів</h3>
              </div>

              <div id="collapseName" class="collapse accordion_content" aria-labelledby="headingName"
                   data-parent="#accordionExample">
                <div>
                  <Details :detailsName="detailsName"/>
                </div>
              </div>
            </div>
            <div class="accordion_row">
              <div class="accordion_header" id="headingOne" data-toggle="collapse"
                   data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                <h3>Матеріали</h3>
                <!--<div v-if="materials.length">({{(materialDefault+1)}})-->
                <!--{{materials[materialDefault].full_name}}-->
                <!--</div>-->
              </div>

              <div id="collapseOne" class="collapse accordion_content show" aria-labelledby="headingOne"
                   data-parent="#accordionExample">
                <div>
                  <materials/>
                </div>
              </div>
            </div>
            <div class="accordion_row">
              <div class="accordion_header" id="headingTwo" data-toggle="collapse"
                   data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                <h3>Кромки</h3>
                <!--<div v-if="edges.length">({{(edgeDefault+1)}}) -->
                <!--{{edges[edgeDefault].full_name}}-->
                <!--</div>-->
              </div>
              <div id="collapseTwo" class="collapse accordion_content" aria-labelledby="headingTwo"
                   data-parent="#accordionExample">
                <div>
                  <edges/>
                </div>
              </div>
            </div>
            <div class="accordion_row">
              <div class="accordion_header" id="headingThree" data-toggle="collapse"
                   data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                <h3>
                  Стяжки
                </h3>
              </div>
              <div id="collapseThree" class="collapse accordion_content" aria-labelledby="headingThree"
                   data-parent="#accordionExample">
                <div>
                  <screeds/>
                </div>
              </div>
            </div>
            <order v-if="isAuth"/>

          </div>
        </div>
        <div class="visual-container">
          <visual v-if="details.length"/>
        </div>
      </div>
      <form-table/>
    </div>

    <div v-else>
      <div class="col-12">
        Ви не авторизовані. Увійдіть через свій обліковий запис Клієнта на порталі.
        <router-link :to="{name: 'login'}">Увійти</router-link>
      </div>
    </div>
    <div v-if="isAuth">
      <details v-if="order">
        <summary>Запит до 1С</summary>
        <pre style="border: 1px solid black">{{ request_data }}</pre>
      </details>
      <details v-if="resp">
        <summary>Відповідь 1С</summary>
        <pre style="border: 1px solid black">{{ resp }}</pre>
      </details>
    </div>
    <search :what="toSearch"/>
    <order-modal/>
  </div>
</template>

<script>
import FormTable from '@/components/parts/DetailTableForm.vue'
import Edges from '@/components/parts/EdgeTable.vue'
import Materials from '@/components/parts/MaterialsTable.vue'
import Details from '@/components/parts/DetailsNameTable.vue'
import Screeds from '@/components/parts/Screeds.vue'
import Visual from '@/components/parts/Visual.vue'
import Search from "@/components/modals/search";
import Order from '@/components/Order.vue'
import OrderModal from "@/components/modals/OrderModal";
import EmailForm from "@/views/User/Settings";
import moment from "moment/moment";

export default {
  data() {
    return {
      user: this.$store.state.auth.user,
      isAuth: this.$store.state.auth.authenticated,
    }
  },
  computed: {
    materials() {
      return this.$store.state.data.materials;
    },
    request_data() {
      return this.$store.state.data.responseDebug && this.$store.state.data.responseDebug.request_data;
    },
    order() {
      return this.$store.state.data.order;
    },
    edges() {
      return this.$store.state.data.edges;
    },
    detailsName() {
      return this.$store.state.data.detailsNames;
    },
    details() {
      return this.$store.state.data.details;
    },
    materialDefault() {
      return this.$store.state.data.materialDefault;
    },
    edgeDefault() {
      return this.$store.state.data.edgeDefault;
    },
    resp() {
      let response1c = this.$store.state.data.response;
      if(response1c && typeof response1c.request_data != 'undefined') {
        delete response1c.request_data;
      }
      return response1c;
    },
    client() {
      return this.$store.state.auth.user;
    },
    toSearch() {
      return app.toSearch;
    }
  },
  methods: {
    search(v) {
      app.toSearch = v;
    },
  },
  watch: {
    client: {
      handler(value, old) {
        this.$store.state.auth.user = this.client;
      },
      deep: true
    },
    details: {
      handler(value, old) {
        app.details = this.details;
      },
      deep: true
    }
  },
  mounted() {
    window.addEventListener("keydown", e => {

      if (e.keyCode === 107 || e.keyCode === 61 || e.keyCode === 43) {
        e.preventDefault();
        document.querySelector('#addNewDetail').click();
      }
      if (e.keyCode === 192) {
        e.preventDefault();
        document.querySelector('input:focus').closest('tr').querySelector('.template').select();
      }

    });
    this.$root.$on('update', i => {
      this.$forceUpdate();
    });
  },
  components: {
    Search,
    FormTable,
    Edges,
    Materials,
    Screeds,
    Visual,
    Order,
    OrderModal,
    Details, EmailForm
  }
}
</script>

<style>
.card-header {
  position: relative;
}

.card-header b, .card-header div {
  position: relative;
  left: 15px
}

.card-header::after {
  font-size: 11px;
  content: "▶";
  color: #333;
  top: 10px;
  left: 5px;
  position: absolute;
}

.card-header[aria-expanded="true"]::after {
  content: "▼";
}

details p {
  text-align: center;
}

.visual-container {
  min-width: 600px;
  min-height: 350px;
  padding-left: 1%;
}

.table {
  margin-bottom: 0 !important;
}

.card-header div {
  font-size: 12px;
  color: black;
}

.card-header button {
  z-index: 999999;
}

.details td, .details th, .tab-content td, .tab-content th {
  padding: .2rem !important;
}

.tab-content .edge_table_wrapper, .tab-content .material_table_wrapper, .tab-content .screed_table_wrapper {
  /*max-height: 250px;*/
  /*overflow-y: auto;*/
  font-size: 13px;
}

.details table {
  font-size: 13px;
}

.help-link {
  position: fixed;
  right: 10px;
  bottom: 10px;
}

.nav-tabs a div {
  font-size: 11px;
}

.dism {
  font-size: 11px !important;
  display: block;
  position: absolute;
  top: 35px;
  right: -3px;
}

.nav-tabs .nav-link {
  color: #495057;
}

.nav-tabs .nav-link.active {
  color: #007bff;
}
</style>