<template>
    <div>
        <button class="btn btn-sm btn-success ml-2" :disabled="materials.length == 0" data-toggle="modal" data-target="#detailModal"><i class="fa fa-list"></i></button>
        <div class="modal fade" id="detailModal" style="overflow: auto!important;" tabindex="-1" role="dialog"
             aria-labelledby="detailModalLabel"
             aria-hidden="true">
            <div class="modal-dialog" style="max-width: 1060px" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                            <h5 class="modal-title" id="detailModalLabel">Деталь</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>

                    </div>
                    <div class="modal-body">

                        <div v-if="detail" class="svg">
                        </div>

                        <table class="table">
                            <tbody>
                            <tr v-if="detail">
                                <td>
                                    <input type="number" step="0.1" min="20" max="9999.9" lang="en-150"
                                           @keyup="onlyNumeric"
                                           @keyup.enter="focusNextInput" @click="ifSelect($event)"
                                           v-model.number="detail.L"/>
                                </td>
                                <td>
                                    <input type="text" @keyup="onlyNumeric" step="1" min="0" max="2"
                                           :value="(detail.edge_b != null && detail.edge_t != null) ? 2 : (detail.edge_t != null ? 1 : 0)"
                                           @input="addEdge($event.target.value, index, 1)"
                                           @keyup.enter="focusNextInput" @click="ifSelect($event)" class="sm-one"/>
                                </td>
                                <td>
                                    <input type="number" step="0.1" min="20" max="9999.9" lang="en-150"
                                           @keyup="onlyNumeric"
                                           @keyup.enter="focusNextInput" @click="ifSelect($event)"
                                           v-model.number="detail.W"/>
                                </td>
                                <td>
                                    <input type="number" @keyup="onlyNumeric" step="1" min="0" max="2"
                                           :value="(detail.edge_r != null && detail.edge_l != null) ? 2 : (detail.edge_l != null ? 1 : 0)"
                                           @input="addEdge($event.target.value, index, 2)"
                                           @keyup.enter="focusNextInput" @click="ifSelect($event)" class="sm-one"/>
                                </td>
                                <td>
                                    <input type="number" @keyup="onlyNumeric" step="1" min="1" lang="en-150"
                                           @keyup.enter="focusNextInput"
                                           @click="ifSelect($event)" v-model.number="detail.Qt" class="one-qt"/>
                                </td>
                                <td>
                            <span class="custom-control custom-checkbox">
                            <input type="checkbox" :id="'Rotate_'+detail.No" class="custom-control-input"
                                   v-model.number="detail.Rotate"
                                   @keyup.enter="focusNextInput"
                                   @keydown="checkbox($event, 'Rotate')"
                                   @change="checkboxChange($event.target.checked, 'Rotate')">
                                <label class="custom-control-label" :for="'Rotate_'+detail.No"></label>
                            </span>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    export default {
        name: "detail-modal",
        props:['detail'],
        methods:{
            onlyNumeric(e) {
                if(e.key != '.' && e.key != ','){
                    e.target.value = e.target.value.replace(/[^0-9\.\,]+/g, '')
                }

            }
        },
        ifSelect(e) {
            if (typeof e.target.select == 'function') {
                e.target.select();
            }
        },
        focusNextInput(e) {
            let nextInput = e.target.parentElement.nextElementSibling || e.target.parentElement.parentElement.nextElementSibling;
            nextInput = nextInput.querySelector('input') || nextInput.querySelector('select');
            if (nextInput) {
                nextInput.focus();
                if (typeof nextInput.select == 'function') {
                    nextInput.select();
                }
            }
        }
    }
</script>

<style scoped>
    /*<detail-modal :detail="detail"></detail-modal>*/
</style>