import axios from 'axios'
import appconfig from "../app/config";

export const orderService = {
    getList, create, createWithFile
}

function getList(params = '') {
    return axios.get(appconfig.Point + 'api/constructor/v1/order' + params)
        .then((response) => Promise.resolve(response.data))
        .catch((error) => Promise.reject(error.response))
}

function create(data) {
    return axios.post(appconfig.Point + 'api/constructor/v1/order/create', data)
        .then((response) => Promise.resolve(response.data))
        .catch((error) => Promise.reject(error.response))
}


function createWithFile(data) {
    return axios.postForm(appconfig.Point + 'api/constructor/v1/order/create', data)
        .then((response) => Promise.resolve(response.data))
        .catch((error) => Promise.reject(error.response))
}
