import router from '../router'
import axios from "axios";
import store from "./index";

export default {
    namespaced: true,
    state: {
        authenticated: false,
        user: {},
        token: null,
    },
    getters: {
        authenticated(state) {
            return state.authenticated
        },
        user(state) {
            return state.user
        },
        token(state) {
            return state.token
        }
    },
    mutations: {
        SET_AUTHENTICATED(state, value) {
            state.authenticated = value
        },
        SET_USER(state, value) {
            state.user = value
        },
        SET_TOKEN(state, value) {
            state.token = value
        }
    },
    actions: {
        login({commit}, data) {
            commit('SET_TOKEN', data.token)
            commit('SET_USER', data.user)
            commit('SET_AUTHENTICATED', true)
            axios.defaults.headers.common['Authorization'] = `Bearer ${store.state.auth.token}`;
            router.push({name: 'Main'})
        },
        logout({commit}) {
            commit('SET_USER', {})
            commit('SET_TOKEN', null)
            commit('SET_AUTHENTICATED', false)
        }
    }
}