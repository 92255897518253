let app = {
    client: {},
    materials:[],
    detailsName:[],
    edges:[],
    screeds:[],

    groups:[],

    details:[],
    detail:{},

    materialDefault: 0,
    edgeDefault: 0,

    toSearch: 'materials',
    replace: null,

    order: null,
    response: null,

    bidPriceItems: []
};

export default app