<template>
  <div>
    <b-modal size="xl"
             header-close-content="×"
             :hide-footer="true"
             id="fileModal" title="Карта крою">
        <embed :src="pdfFile"
               type="application/pdf">
    </b-modal>
  </div>
</template>

<script>

export default {
  name: "pdf-modal",
  props: ['pdfExists', 'pdfFile'],
}
</script>

<style scoped>
#fileModal .modal-content {
  height: 100%;
}
#fileModal embed {
  width: 100%;
  height: 65vw;
}

</style>