<template>
  <div id="app">
    <div class="preloader" id="preloader" ref="preloader">
      <div class="item-1"></div>
      <div class="item-2"></div>
      <div class="item-3"></div>
      <div class="item-4"></div>
      <div class="item-5"></div>
    </div>

    <header class="header">
      <div class="container">

        <nav-menu/>
      </div>
    </header>

    <section id="view-section" class="view">
      <div class="container">
        <router-view/>
      </div>
    </section>

    <footer class="footer">
      <div class="container">
        <footer-block/>
      </div>
    </footer>
  </div>

</template>

<script>
import NavMenu from '@/components/parts/NavMenu.vue'
import FooterBlock from '@/components/parts/Footer.vue'
import axios from "axios";
import {mapActions} from 'vuex'

export default {
  data() {
    return {
      app: app,
      user: this.$store.state.auth.user
    }
  },
  watch: {
  },
  methods: {
    ...mapActions({
      signOut: "auth/logout"
    }),
    async logout() {
      await axios.post('/logout').then(({data}) => {
        this.signOut()
        this.$router.push({name: "login"})
      })
    }
  },
  mounted() {
    this.$refs.preloader.classList.add('hide')
  },
  components: {
    NavMenu,
    FooterBlock
  }
}

</script>
