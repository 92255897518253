<template>
  <div class="footer__wrap">
    <ul class="footer__list">
      <li>
        <router-link :to="{name:'Prav'}">Умови роботи</router-link>
      </li>
      <li>
        <router-link :to="{name:'Delivery'}">Доставка</router-link>
      </li>
      <li>
        <router-link :to="{name:'Payment'}">Оплата</router-link>
      </li>
    </ul>
    <div class="footer__info">
      <a href="tel:+380730070403"><i class="fa fa-phone"></i>+38 (073) 007 04 03</a>
      <a href="mailto:sale@mm.ck.ua"><i class="far fa-envelope"></i>sale@mm.ck.ua</a>
    </div>
  </div>
</template>

<script>

export default {
  data() {
    return {
      url: appconfig.Point
    }
  }
}
</script>