let appconfig = {
    environment : 'production',
    Host: 'https://pro.btb.co.ua/',
    Point: 'https://dev.btb.co.ua/',
    HostDev: 'https://pro.btb.test/',
    PointDev: 'https://btb.test/',
    apiPoint: 'api/constructor/v1/',
    createOrder: 'api/constructor/v1/create/order',
    isLocal: process.env.VUE_APP_IS_LOCAL
};
if(appconfig.isLocal) {
    appconfig.Host = appconfig.HostDev;
    appconfig.Point = appconfig.PointDev;
}

export default appconfig