<template>
  <div class="material_table_wrapper">
    <table class="material_table table">
      <thead>
      <tr>
        <th>
          <div class="table__header">
            №
          </div>
        </th>
        <th>
          <div class="table__header">
            Назва
          </div>
        </th>
        <th colspan="2">
          <div class="table__header">
            <span></span>
            <button class="button button__medium" @click="add">
              Додати
            </button>
          </div>
        </th>
      </tr>
      </thead>
      <tbody v-if="detailsName">
      <tr v-for="(detail, index) in detailsName" :key="index">
        <td>{{ index + 1 }}</td>
        <td colspan="2">
          <div class="table__text">
            <input :id="'dt_'+index"
                   placeholder="Назва"
                   v-model="detail.name"
                   type="text">
            <label :for="'dt_'+index"></label>
          </div>
        </td>
        <td>
          <div class="table__buttons">
            <button class="button button__icon" @click="remove(index)">
              <img :src="require('@/assets/img/trash-can.png')">
            </button>
          </div>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: "DetailsNameTable",
  props: ['detailsName'],
  methods: {
    add() {
      this.detailsName.push({name: ''})
    },
    remove(i) {
      this.detailsName.splice(i, 1);
    }
  },
  mounted() {
  }
}
</script>